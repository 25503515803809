import { ReactNode } from 'react'
import cn from 'classnames'

import { useDropDownMenu } from 'mmfintech-commons'

import { DropDownButton, DropDownContent, DropDownWrapper } from './CustomDropdown.styled'

import { ReactComponent as ChevronUp } from '../../images/icons/filter-chevron-up.svg'
import { ReactComponent as ChevronDown } from '../../images/icons/filter-chevron-down.svg'

type CustomDropdownProps = {
  icon?: ReactNode
  caption?: string
  dropdown?: ReactNode
}

export const CustomDropdown = ({ icon, caption, dropdown }: CustomDropdownProps) => {
  const { visible, toggleDropDown } = useDropDownMenu()

  return (
    <DropDownWrapper>
      {caption ? (
        <DropDownButton className={cn({ opened: visible })} onClick={toggleDropDown}>
          <span>
            {icon ? <span className='icon'>{icon}</span> : null}
            {caption}
          </span>
          <span className='chevron'>{visible ? <ChevronUp /> : <ChevronDown />}</span>
        </DropDownButton>
      ) : icon ? (
        <div className='icon-container' onClick={toggleDropDown}>
          {icon}
        </div>
      ) : null}
      <DropDownContent className={cn({ opened: visible })} alternative={!caption}>
        {dropdown}
      </DropDownContent>
    </DropDownWrapper>
  )
}
