import { Button, DataTable } from 'mmfintech-portal-commons'
import { formatDateLocal, formatMoney, tr } from 'mmfintech-commons'
import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { CustomerRoleEnum, ExpenseView } from 'mmfintech-commons-types'

import { ReactComponent as ClearIcon } from '../../images/icons/x-mark.svg'
import { ReactComponent as Checkmark } from '../../images/icons/checkmark.svg'

const { Row, Cell } = DataTable

type RequestsTableRowProps = {
  transaction: ExpenseView
  handleApprove: (requestId: any, sourceAccountId: any) => void
  handleReject: (requestId: any) => void
}

export const RequestTableRow = ({ transaction, handleApprove, handleReject }: RequestsTableRowProps) => {
  const { requestId, username, description, createdOn, amount, currency, status, sourceAccountId, bankAccountOut } =
    transaction || {}

  const customerRole = useAppSelector(selectCurrentUserRole)

  return (
    <>
      <Row data-test='table-row'>
        <Cell data-test='name'>{username}</Cell>
        <Cell data-test='description'>{description}</Cell>
        <Cell data-test='date'>{formatDateLocal(createdOn)}</Cell>
        <Cell data-test='amount'>{formatMoney(amount, currency)}</Cell>
        <Cell className='small' data-test='recipient'>
          {bankAccountOut?.name}
        </Cell>
        <Cell className='action-buttons'>
          {status === 'PENDING' ? (
            <>
              {isOwnerOrAdministrator(customerRole) && (
                <Button
                  className='approve'
                  type='button'
                  color='round-secondary'
                  text={tr('FRONTEND.EXPENSE_MANAGEMENT.BUTTONS.APPROVE', 'Approve')}
                  onClick={() => handleApprove(requestId, sourceAccountId)}
                />
              )}
              <Button
                className='reject'
                type='button'
                color='round-secondary'
                text={
                  customerRole === CustomerRoleEnum.STAFF
                    ? tr('FRONTEND.EXPENSE_MANAGEMENT.BUTTONS.CANCEL', 'Cancel')
                    : tr('FRONTEND.EXPENSE_MANAGEMENT.BUTTONS.DECLINE', 'Decline')
                }
                onClick={() => handleReject(requestId)}
              />
            </>
          ) : (
            <div className='status'>
              <span className='icon'>{status === 'APPROVED' ? <Checkmark /> : <ClearIcon />}</span>
              {status}
            </div>
          )}
        </Cell>
      </Row>
    </>
  )
}
