import styled from 'styled-components'

export const SelectAccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`

export const SelectAccountLabel = styled.div`
  color: #454a54;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4rem;
  margin-bottom: 0.5rem;
`

export const SelectAccountStyled = styled.div`
  width: 100%;
  position: relative;
`

export const AccountButtonStyled = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(172, 182, 191, 0.25);

  width: 100%;
  max-width: 100%;
  min-height: 4.4rem;
  padding: 1rem 1.8rem 1rem 1rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  color: #a3b1bf;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;

  &.error {
    border-color: #ff3131;
  }
  &.static {
    cursor: default;
  }
  &.bordered {
    border: 1px solid #e1e6ea;
    box-shadow: none;
    margin-bottom: 2rem;
  }
  &.is-selected {
    background-color: rgba(22, 177, 18, 0.08);
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .account-info {
    display: flex;
    align-items: center;

    .icon {
      width: 26px;
      height: 26px;
      margin-right: 1rem;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;
      font-size: 1.4rem;

      &.recipient {
        color: #454a54;
      }
      .balance {
        color: #454a54;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4rem;
      }
    }
  }

  .no-account {
    color: #a3b1bf;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    padding: 0 1.6rem;
  }
`

export const AccountListStyled = styled.div<{ opened: boolean }>`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: 101;
  max-height: 25rem;

  background-color: #ffffff;
  border-radius: 11px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
  padding: 1rem 0;

  overflow-y: auto;

  color: #808080;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  .currency-name {
    color: #000 !important;
  }
`

export const AccountStyled = styled.div`
  width: 100%;
  height: 4rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 1rem;

  .custom-option-wrapper {
    display: flex;
    flex-flow: column nowrap;
    color: #454a54;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7rem;
    .custom-option-description {
      color: #808080;
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  :hover {
    background: rgba(220, 223, 227, 0.4);
  }

  .icon {
    width: 26px;
    height: 26px;
    margin-right: 1rem;

    .icon-all {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      background: #85a0ad;
      border-radius: 4px;
      color: #ffffff;
      font-family: inherit;
      font-size: 1rem;
      font-style: normal;
      line-height: 1.2rem;
    }
  }

  .content {
    display: flex;
    flex-flow: column nowrap;

    .balance {
      color: #000000;
      font-weight: 500;
    }
  }
`

export const SelectAccountError = styled.div`
  color: #ff3131;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;

  margin-top: 5px;
  margin-left: 2.5rem;
`

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 1rem 3rem;

  .button {
    height: 3.4rem;
    width: 100%;
    max-width: 30rem;

    font-size: 1.2rem;
    line-height: 120%;
  }
`

export const AddButtonInner = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
`

export const OtherOptionsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  background-color: #85a0ad;

  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.9rem;
`
