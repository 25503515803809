import styled from 'styled-components'

export const PreviewContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  padding: 3rem 3rem 1rem;

  .select-account-wrapper {
    margin: 3rem 0 1rem;
  }

  .subtitle {
    color: #454a54;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8rem;
    margin-bottom: 3rem;
  }

  @media (min-width: 450px) {
    padding: 4rem 5rem 2rem;
  }

  .row {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 0;

    color: #000;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;

    .label {
      flex: 1;
      color: #a3b1bf;
      font-weight: 400;
    }

    .value {
      flex: 2;
      font-weight: 400;
      white-space: normal !important;
      word-break: break-all !important;
      overflow-wrap: anywhere !important;
    }

    @media (min-width: 450px) {
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
`

export const PreviewButtons = styled.div`
  margin: 3rem 0;
`
export const PreviewAmount = styled.div`
  border-top: 1px solid #e1e5ea;
  padding: 1rem 0;
  margin-top: 2rem;
  margin-bottom: 4rem;

  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  color: #454a54;

  .label {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .amount {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
`
