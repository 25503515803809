import moment from 'moment'

import { Button, Input } from 'mmfintech-portal-commons'
import { CheckBoxGroup, CustomDropdown } from '../../../components'
import {
  CheckBoxGroupContainer,
  FilterButtons,
  FiltersContainer,
  FilterSelectStyles,
  FilterWrapper
} from './TransactionsFilter.styled'

import { checkSingleValue, tr } from 'mmfintech-commons'
import { selectCurrentLanguage, useAppSelector, useCurrencies, usePaymentMethods } from 'mmfintech-backend-api'

import { ReactComponent as ClearIcon } from '../../../images/icons/clear-all.svg'
import { ReactComponent as SearchIcon } from '../../../images/icons/search.svg'

export const TransactionsFilter = ({ filter }) => {
  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const { currencyOptionsSimple } = useCurrencies()
  const { paymentMethodOptions } = usePaymentMethods()

  const transactionStatuses = [
    { value: 'INITIAL', label: tr('METADATA.TRANSACTION_STATUS.INITIAL', 'INITIAL') },
    { value: 'PENDING', label: tr('METADATA.TRANSACTION_STATUS.PENDING', 'PENDING') },
    { value: 'SCHEDULED', label: tr('METADATA.TRANSACTION_STATUS.SCHEDULED', 'SCHEDULED') },
    { value: 'PROCESSED', label: tr('METADATA.TRANSACTION_STATUS.PROCESSED', 'PROCESSED') },
    { value: 'CONFIRMED', label: tr('METADATA.TRANSACTION_STATUS.CONFIRMED', 'CONFIRMED') },
    { value: 'CANCELLED', label: tr('METADATA.TRANSACTION_STATUS.CANCELLED', 'CANCELLED') },
    { value: 'FAILED', label: tr('METADATA.TRANSACTION_STATUS.FAILED', 'FAILED') }
  ]

  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_CURRENCIES', 'All') },
    ...currencyOptionsSimple
  ]

  return (
    <FilterWrapper>
      <FiltersContainer>
        <Input
          type='date'
          name='from'
          selectedLanguage={selectedLanguage}
          maxDate={filter.values.get('to')}
          label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_DATE', 'From Date')}
          {...filter.values.registerInput('from')}
          data-test='filter-from-date'
          hideErrorLine
        />
        <Input
          type='date'
          name='to'
          selectedLanguage={selectedLanguage}
          minDate={filter.values.get('from')}
          maxDate={moment()}
          label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_DATE', 'To Date')}
          {...filter.values.registerInput('to')}
          data-test='filter-to-date'
          hideErrorLine
        />
        {filter.isVisible('id') && (
          <Input
            type='number'
            name='id'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TRANSACTION_ID', 'ID')}
            {...filter.values.registerInput('id')}
            data-test='filter-id'
            hideErrorLine
          />
        )}
        {filter.isVisible('reference') && (
          <Input
            type='text'
            name='reference'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.REFERENCE', 'Reference')}
            {...filter.values.registerInput('reference')}
            data-test='filter-reference'
            hideErrorLine
          />
        )}
        {filter.isVisible('foreignId') && (
          <Input
            type='text'
            name='foreignTransactionId'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FOREIGN_ID', 'Foreign ID')}
            {...filter.values.registerInput('foreignTransactionId')}
            data-test='filter-foreign-id'
            hideErrorLine
          />
        )}
        {filter.isVisible('customer') && (
          <Input
            type='text'
            name='description'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.CUSTOMER_EMAIL', 'Customer Email')}
            {...filter.values.registerInput('description')}
            data-test='filter-customer'
            hideErrorLine
          />
        )}
        {filter.isVisible('paymentMethod') && (
          <Input
            type='select'
            name='paymentMethods'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYMENT_METHOD', 'Payment method')}
            {...filter.values.registerInput('paymentMethods')}
            styles={FilterSelectStyles}
            options={paymentMethodOptions}
            data-test='filter-payment-method'
            hideErrorLine
            isMultiSelect
            isSearchable={false}
          />
        )}
        {filter.isVisible('status') && (
          <Input
            type='select'
            name='statuses'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.STATUS', 'Status')}
            {...filter.values.registerInput('statuses')}
            styles={FilterSelectStyles}
            options={transactionStatuses}
            data-test='filter-status'
            hideErrorLine
            isSearchable={false}
            isMultiSelect={true}
          />
        )}
        {filter.isVisible('currency') && (
          <Input
            type='select'
            name='currency'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.CURRENCY', 'Currency')}
            {...filter.values.registerInput('currency')}
            styles={FilterSelectStyles}
            options={getCurrencyOptions()}
            data-test='filter-currency'
            hideErrorLine
          />
        )}
        {filter.isVisible('amount') && (
          <Input
            type='text'
            name='fromAmount'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From Amount')}
            value={filter.values.get('fromAmount')}
            onChange={(name: string, value: string) => {
              filter.values.set(name, checkSingleValue(value, { validation: 'float' }))
              filter.values.reload()
            }}
            data-test='filter-from-amount'
            hideErrorLine
          />
        )}
        {filter.isVisible('amount') && (
          <Input
            type='text'
            name='toAmount'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To Amount')}
            value={filter.values.get('toAmount')}
            onChange={(name: string, value: string) => {
              filter.values.set(name, checkSingleValue(value, { validation: 'float' }))
              filter.values.reload()
            }}
            data-test='filter-to-amount'
            hideErrorLine
          />
        )}
        <Button
          type='button'
          color='delete'
          className='mr-1 apply-button'
          onClick={() => filter.delayedReload.execute()}
          text={tr('FRONTEND.TRANSACTIONS.FILTER.SEARCH_BUTTON', 'Search')}
          icon={<SearchIcon />}
        />
      </FiltersContainer>
      <FilterButtons>
        <Button
          type='button'
          color='delete'
          className='mr-1'
          onClick={() => {
            filter.clearAll()
          }}
          text={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Reset')}
          icon={<ClearIcon />}
        />

        <CustomDropdown
          caption={tr('FRONTEND.TRANSACTIONS.FILTER.FILTERS_BUTTON', 'Filters')}
          dropdown={
            <CheckBoxGroupContainer>
              <CheckBoxGroup
                items={filter.meta}
                checked={filter.isVisible}
                setChecked={name => {
                  filter.setVisible(name)
                  filter.values.reload()
                }}
                header={tr('FRONTEND.TRANSACTIONS.FILTER.CHOOSE_FILTERS', 'Choose filters')}
                footer={
                  <Button
                    type='button'
                    color='round-secondary'
                    className='sticky'
                    text={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_FILTERS_BUTTON', 'Clear Filters')}
                    icon={<ClearIcon />}
                    onClick={() => {
                      filter.clearFilters()
                      filter.delayedReload.execute()
                    }}
                  />
                }
              />
            </CheckBoxGroupContainer>
          }
        />
      </FilterButtons>
    </FilterWrapper>
  )
}
