import React from 'react'
import { useHistory } from 'react-router-dom'

import { CurrencyIcon } from '../../components'
import { CurrencyWrapper } from './Accounts.styled'
import { Button, DataTable } from 'mmfintech-portal-commons'

import { paths, useAccessPermissions, useCurrencies } from 'mmfintech-backend-api'
import { copyTextToClipboard, formatMoney, tr, useWindowSize } from 'mmfintech-commons'

import { ReactComponent as EditIcon } from '../../images/icons/edit-account.svg'
import { ReactComponent as CopyIcon } from '../../images/icons/copy-icon.svg'

export const AccountDataRow = ({ account, handleEdit }) => {
  const { id: accountId, currencyCode, balance, name: accountName, dedicatedMerchantBankAccounts, ibanStatus } = account
  const history = useHistory()
  const { getCurrencyPrecision } = useCurrencies()

  const { isOwnerOrAdministrator } = useAccessPermissions()

  const [width] = useWindowSize()

  const editAccount = (e: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    handleEdit(account)
  }

  const showIbanStatus =
    ['CREATED', 'PENDING', 'WAITING_ACTION'].includes(ibanStatus) && dedicatedMerchantBankAccounts.length === 0

  const showTransactions = (accountId: number): void => {
    history.push(paths.banking.transactions.listByAccountId(accountId))
  }

  return (
    <DataTable.Row
      // data-for='info-tooltip'
      // data-tip={tr(
      //   'FRONTEND.ACCOUNTS.TOOLTIP',
      //   'You can choose the account, whose balance and transactions to view here.'
      // )}
      onClick={() => {
        if (width >= 768) {
          showTransactions(accountId)
        }
      }}
      data-test='table-row'>
      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.CURRENCY', 'Currency')} className='small'>
        <CurrencyWrapper>
          <CurrencyIcon
            currency={currencyCode}
            width='3rem'
            height='3rem'
            circle={false}
            className='mr-1 currency-icon'
          />
          <span data-test='currency'>{currencyCode}</span>
        </CurrencyWrapper>
      </DataTable.Cell>

      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.NAME', 'Account Name')}>
        <span data-test='account-name'>{accountName}</span>
      </DataTable.Cell>

      <DataTable.Cell caption='Account details'>
        <span data-test='account-details'>
          {dedicatedMerchantBankAccounts.length > 0 ? (
            <span className='account-details-iban'>
              IBAN: {dedicatedMerchantBankAccounts[0]?.iban}{' '}
              <CopyIcon
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyTextToClipboard(dedicatedMerchantBankAccounts[0]?.iban)
                }}
              />
            </span>
          ) : null}
          {showIbanStatus ? tr('FRONTEND.ACCOUNTS.IBAN_REQUESTED.TITLE', 'IBAN Requested') : null}
        </span>
      </DataTable.Cell>

      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.ACCOUNT_ID', 'ID')} className='small'>
        <span data-test='account-id'>{accountId}</span>
      </DataTable.Cell>

      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.BALANCE', 'Balance')} className='small'>
        <span data-test='balance'>{formatMoney(balance, currencyCode, getCurrencyPrecision(currencyCode))}</span>
      </DataTable.Cell>

      {isOwnerOrAdministrator() ? (
        <DataTable.Cell className='edit-cell icon'>
          <span
            data-test='edit-button'
            onClick={editAccount}
            title={tr('FRONTEND.ACCOUNTS.COLUMNS.EDIT_ACCOUNT', 'Edit account')}>
            <EditIcon />
          </span>
        </DataTable.Cell>
      ) : null}
      <DataTable.Cell className='transactions-cell no-border'>
        <Button
          color='round-primary'
          text={tr('FRONTEND.ACCOUNTS.COLUMNS.VIEW_TRANSACTIONS', 'View transactions')}
          onClick={() => showTransactions(accountId)}
          data-test='transactions-button'
        />
      </DataTable.Cell>
      {isOwnerOrAdministrator() ? (
        <DataTable.Cell className='transactions-cell no-border'>
          <Button
            color='round-primary'
            text={tr('FRONTEND.ACCOUNTS.COLUMNS.EDIT_ACCOUNT', 'Edit account')}
            onClick={editAccount}
            data-test='edit-button'
          />
        </DataTable.Cell>
      ) : null}
    </DataTable.Row>
  )
}
