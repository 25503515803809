import styled from 'styled-components'

import PaymentsIcon from '../../../images/icons/dashboard-payments.svg'
import PayoutsIcon from '../../../images/icons/dashboard-payouts.svg'
import PayoutsIconJpy from '../../../images/icons/dashboard-payouts-jpy.png'
import TransactionsIcon from '../../../images/icons/dashboard-transactions.svg'

export const TotalsSectionStyled = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  height: 34rem;
`

export const TotalsHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2.5rem 3.5rem 3rem;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    /* width: 10rem; */
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #1e63ff;
    }
  }
`

export const TotalsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3.5rem;
`

export const TotalsContent = styled.div`
  padding: 0 2.5rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  color: #454a54;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .icon {
    display: inline-block;
    position: relative;
    height: 50px;
    width: 50px;
    margin-right: 2rem;

    :after {
      position: absolute;
      left: -17px;
      top: -13px;
      right: -17px;
      bottom: -21px;
      content: '';
      background-size: contain;
    }
    &.payments:after {
      background: url(${PaymentsIcon}) center no-repeat;
    }
    &.transactions:after {
      background: url(${TransactionsIcon}) center no-repeat;
    }
    &.payouts:after {
      background: url(${PayoutsIcon}) center no-repeat;
    }
    &.payouts.jpy:after {
      background: url(${PayoutsIconJpy}) center no-repeat;
    }
  }

  .total {
    display: inline-block;

    .label {
      display: block;
      margin-bottom: 2px;

      color: #a3b1bf;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`
