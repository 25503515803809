import styled from 'styled-components'

import hamburgerMenuIcon from '../../images/icons/hamburger-menu.svg'

export const HeaderWrapper = styled.div`
  grid-area: header;

  //display: grid;
  //grid-template-columns: 1fr minmax(320px, 1240px) 1fr;

  margin: 0;
  padding: 0;
  width: 100%;

  z-index: 99;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .overlay {
    z-index: 10;
    background: rgba(96, 96, 96, 0.5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  a {
    white-space: nowrap;
  }
`

export const HeaderContainer = styled.nav<{ maxMobileWidth: number }>`
  grid-column: 2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 0 2rem;
`

const HeaderButtons = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;

    white-space: nowrap;

    color: #454a54;
    text-decoration: none;
    padding: 0 2rem 0.5rem;

    &.active-tab {
      color: #1e63ff;
      font-weight: 500;
      border-bottom: 2px solid #1e63ff;
    }
    &.disabled {
      pointer-events: none;
    }
  }
`

export const HeaderMenuWrapper = styled(HeaderButtons)`
  display: flex;
  align-items: center;
`

export const BrandLogoWrapper = styled.div``

export const HeaderInnerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const Hamburger = styled(HeaderButtons)`
  padding-right: 1rem;
  @media (min-width: 320px) {
    padding-right: 1.5rem;
  }

  div.title {
    color: #454a54;
  }

  a {
    margin-bottom: 0;
    padding: 1.6rem 2.5rem;

    &.active-tab {
      border-bottom: none;
    }
  }
`

export const HamburgerIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;
  background: transparent;

  :focus {
    outline: none;
  }

  svg:hover > * {
    fill: #bdbdbd;
  }

  .menu-icon {
    width: 2.5rem;
    height: 2.5rem;
    background: url(${hamburgerMenuIcon}) center center no-repeat;
  }
`

export const HamburgerMenu = styled.nav<{ open: boolean; maxMobileWidth: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #454a54;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.7rem;

  position: absolute;
  top: 0;
  right: 0;
  padding: 3rem;
  width: 22rem;
  height: 100vh;
  z-index: 10000;

  background: #ffffff;
  box-shadow: 0 0 10px rgb(150, 150, 150, 0.5);
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};

  a,
  div.title {
    color: #454a54;
    &.active-tab {
      color: #454a54;
      font-weight: normal;
    }
  }

  a {
    &.sign-up {
      height: 6rem;
      text-align: center;
      margin: 0;
      width: 100%;

      color: #454a54;
      background: #ffffff;
    }

    &.verification-userStatus {
      text-align: center;
    }
  }

  @media (max-width: ${({ maxMobileWidth }) => `${maxMobileWidth}px`}) {
    align-items: flex-start;
    justify-content: start;
    padding: 2rem;

    a {
      padding: 0;
      height: 6rem;
      text-align: left;
      font-size: 1.6rem;
      line-height: 1.9rem;

      &.sign-up {
        margin: 2rem 0 0;
      }
    }
  }

  @media (max-width: 515px) {
    height: 100vh;
    .dummy {
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
  }
`
