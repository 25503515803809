import styled from 'styled-components'

import CheckedIcon from '../../images/icons/checkbox.svg'

export const CheckboxWrapper = styled.span`
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  .checkbox-icon {
    display: inline-block;
    width: 12px !important;
    height: 12px !important;

    border: 1px solid #d2d8e0;
    border-radius: 2px;
  }

  &.checked {
    .checkbox-icon {
      background: rgba(30, 99, 255, 0.7) url(${CheckedIcon}) center center no-repeat;
      border: none;
    }
  }
`
